// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import AutomaticFormCreation from "../../blocks/AutomaticFormCreation/src/AutomaticFormCreation";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Mentionstagging from "../../blocks/Mentionstagging/src/Mentionstagging";
import Maps from "../../blocks/maps/src/Maps";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import OrderCreation from "../../blocks/OrderCreation/src/OrderCreation";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import FullTeamVisibility2 from "../../blocks/FullTeamVisibility2/src/FullTeamVisibility2";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import BhimUpiIntegration2 from "../../blocks/BhimUpiIntegration2/src/BhimUpiIntegration2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CfIntegrating3dDesignsOnPlatform from "../../blocks/CfIntegrating3dDesignsOnPlatform/src/CfIntegrating3dDesignsOnPlatform";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import MatchAlgorithm from "../../blocks/MatchAlgorithm/src/MatchAlgorithm";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import DownloadableProducts2 from "../../blocks/DownloadableProducts2/src/DownloadableProducts2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Location from "../../blocks/location/src/Location";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OrderManagementSync2 from "../../blocks/OrderManagementSync2/src/OrderManagementSync2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import SummaryCard from "../../blocks/SummaryCard/src/SummaryCard";
import CoreMachineLearning2 from "../../blocks/CoreMachineLearning2/src/CoreMachineLearning2";
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";



const routeMap = {
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
RecommendationEngine4:{
 component:RecommendationEngine4,
path:"/RecommendationEngine4"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Mentionstagging:{
 component:Mentionstagging,
path:"/Mentionstagging"},
Maps:{
 component:Maps,
path:"/Maps"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
OrderCreation:{
 component:OrderCreation,
path:"/OrderCreation"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
FullTeamVisibility2:{
 component:FullTeamVisibility2,
path:"/FullTeamVisibility2"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ServiceSpecificSettingsAdmin:{
 component:ServiceSpecificSettingsAdmin,
path:"/ServiceSpecificSettingsAdmin"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
BhimUpiIntegration2:{
 component:BhimUpiIntegration2,
path:"/BhimUpiIntegration2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CfIntegrating3dDesignsOnPlatform:{
 component:CfIntegrating3dDesignsOnPlatform,
path:"/CfIntegrating3dDesignsOnPlatform"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
MatchAlgorithm:{
 component:MatchAlgorithm,
path:"/MatchAlgorithm"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
DownloadableProducts2:{
 component:DownloadableProducts2,
path:"/DownloadableProducts2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Location:{
 component:Location,
path:"/Location"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OrderManagementSync2:{
 component:OrderManagementSync2,
path:"/OrderManagementSync2"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
SummaryCard:{
 component:SummaryCard,
path:"/SummaryCard"},
CoreMachineLearning2:{
 component:CoreMachineLearning2,
path:"/CoreMachineLearning2"},
StoreLocator:{
 component:StoreLocator,
path:"/StoreLocator"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;